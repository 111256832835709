import Vue from 'vue'
import VueRouter from 'vue-router'
import homeView from '../views/HomeView.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: homeView,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/HomePageView.vue')
      }
    ]
  },
  // 漫影隐私协议
  {
    path: '/MYPrivacyAgreement',
    name: 'MYPrivacyAgreement',
    component: () => import('../views/PrivacyAgreement.vue')
  },
  // 漫影会员协议
  {
    path: '/MYMembershipAgreement',
    name: 'MYMembershipAgreement',
    component: () => import('../views/MembershipAgreement.vue')
  },
  // 随意记隐私协议
  {
    path: '/NotePrivacyAgreement',
    name: 'NotePrivacyAgreement',
    component: () => import('../views/NotePrivacyAgreement.vue')
  },
  // 随意记用户服务协议
  {
    path: '/NoteUserServiceAgreement',
    name: 'NoteUserServiceAgreement',
    component: () => import('../views/NoteUserServiceAgreement.vue')
  },
  // EP隐私协议
  {
    path: '/EPPrivacyAgreement',
    name: 'EPPrivacyAgreement',
    component: () => import('../views/EPPrivacyAgreement.vue')
  },
  // EP用户服务协议
  {
    path: '/EPUserServiceAgreement',
    name: 'EPUserServiceAgreement',
    component: () => import('../views/EPUserServiceAgreement.vue')
  },
  // 智趣答题隐私协议
  {
    path: '/question/agreement/privacy',
    name: 'QuestionPrivacyAgreement',
    component: () => import('../views/question/PrivacyAgreement.vue')
  },
  // 智趣答题隐私协议-B面
  {
    path: '/question/agreement/privacy/detail',
    name: 'QuestionPrivacyAgreementDetail',
    component: () => import('../views/question/PrivacyAgreement.vue')
  },
  // 智趣答题用户协议
  {
    path: '/question/agreement/user',
    name: 'QuestionUserAgreement',
    component: () => import('../views/question/UserAgreement.vue')
  },
  // 智趣答题用户协议-市场
  {
    path: '/question/agreement/user/market',
    name: 'QuestionUserAgreementMarket',
    component: () => import('../views/question/UserAgreementMarket.vue')
  },
  // 随意记隐私协议-英文
  {
    path: '/note/agreement/privacy/en',
    name: 'NotePrivacyAgreementEn',
    component: () => import('../views/note/PrivacyAgreementEn.vue')
  },
  // 随意记用户服务协议-英文
  {
    path: '/note/agreement/user/en',
    name: 'NoteUserServiceAgreementEn',
    component: () => import('../views/note/UserServiceAgreementEn.vue')
  },
  // 输入法隐私协议-英文
  {
    path: '/keyboard/agreement/privacy/en',
    name: 'KeyboardPrivacyAgreementEn',
    component: () => import('../views/keyboard/PrivacyAgreementEn.vue')
  },
  // 输入法用户服务协议-英文
  {
    path: '/keyboard/agreement/user/en',
    name: 'KeyboardUserServiceAgreementEn',
    component: () => import('../views/keyboard/UserServiceAgreementEn.vue')
  },
  // 成语大闯关隐私协议
  {
    path: '/idiom/agreement/privacy',
    name: 'IdiomPrivacyAgreement',
    component: () => import('../views/idiom/PrivacyAgreement.vue')
  },
  // 成语大闯关用户协议
  {
    path: '/idiom/agreement/user',
    name: 'IdiomUserAgreement',
    component: () => import('../views/idiom/UserAgreement.vue')
  },
  // 成语大闯关用户协议-市场
  {
    path: '/idiom/agreement/user/market',
    name: 'IdiomUserAgreementMarket',
    component: () => import('../views/idiom/UserAgreementMarket.vue')
  },
  // 行走乐动记隐私协议
  {
    path: '/walk/agreement/privacy',
    name: 'walkPrivacyAgreement',
    component: () => import('../views/walk/PrivacyAgreement.vue')
  },
  // 行走乐动记用户协议
  {
    path: '/walk/agreement/user',
    name: 'walkUserAgreement',
    component: () => import('../views/walk/UserAgreement.vue')
  },
  // 行走乐动记用户协议-市场
  {
    path: '/walk/agreement/user/market',
    name: 'walkUserAgreementMarket',
    component: () => import('../views/walk/UserAgreementMarket.vue')
  },
  // 成语飞花隐私协议
  {
    path: '/feihua/agreement/privacy',
    name: 'feihuaPrivacyAgreement',
    component: () => import('../views/feihua/PrivacyAgreement.vue')
  },
  // 成语飞花用户协议
  {
    path: '/feihua/agreement/user',
    name: 'feihuaUserAgreement',
    component: () => import('../views/feihua/UserAgreement.vue')
  },
  // 成语飞花用户协议-市场
  {
    path: '/feihua/agreement/user/market',
    name: 'feihuaUserAgreementMarket',
    component: () => import('../views/feihua/UserAgreementMarket.vue')
  },
  // 鸿运联珠隐私协议
  {
    path: '/hongyun/agreement/privacy',
    name: 'hongyunPrivacyAgreement',
    component: () => import('../views/hongyun/PrivacyAgreement.vue')
  },
  // 鸿运联珠用户协议
  {
    path: '/hongyun/agreement/user',
    name: 'hongyunUserAgreement',
    component: () => import('../views/hongyun/UserAgreement.vue')
  },
  // 鸿运联珠用户协议-市场
  {
    path: '/hongyun/agreement/user/market',
    name: 'hongyunAgreementMarket',
    component: () => import('../views/hongyun/UserAgreementMarket.vue')
  },
  // 活力漫步隐私协议
  {
    path: '/active/agreement/privacy',
    name: 'activePrivacyAgreement',
    component: () => import('../views/active/PrivacyAgreement.vue')
  },
  // 活力漫步用户协议
  {
    path: '/active/agreement/user',
    name: 'activeUserAgreement',
    component: () => import('../views/active/UserAgreement.vue')
  },
  // 活力漫步用户协议-市场
  {
    path: '/active/agreement/user/market',
    name: 'activeUserAgreementMarket',
    component: () => import('../views/active/UserAgreementMarket.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
  // scrollBehavior (to, from, savedPosition) {
  //   // 解决路由跳转后 会滚动到底部
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // }
})
// 路由守卫
export default router
